import { useState } from "react";
import { useCallback } from "react";

//useRequest receives an axios post request from that returns a promise
//and returns an axios request and various states i.e data, loading, error states
//the axios post request can then be called onSubmit and the various status of the
//request tracked using the states returned alongside it.

const useRequest = (service, ...rest) => {
   const [data, setData] = useState("");
   const [loading, setIsLoading] = useState(false);
   const [error, setError] = useState("");

   //create a function that receives queryObj makes axios query call when called
   const queryFn = useCallback(
      (queryObj, options) => {
         //set default functions to prevent type error should callback be undefined
         const { onSuccess = (data) => data, onError = (message) => message } =
            options;

         setIsLoading(true);
         setData("");
         setError("");

         service(queryObj, ...rest)
            .then((response) => {
               setIsLoading(false);
               const { data } = response;

               if (data.status === "error") {
                  onError && onError(data.message);
                  setError(data.message);
               }
               if (data.status === "success") {
                  onSuccess(data.data);
                  setData(data.data);
               }
            })
            .catch((err) => {
               onError && onError(err.message);
               setError(err.message);
            });
      },
      [service, rest],
   );
   return { queryFn, data, loading, error };
};

export default useRequest;
