import React from "react";
import BankIcon from "../../../assets/icons/LedgaV2 Icons/basil_bank-solid.svg";
import VFDIcon from "../../../assets/icons/LedgaV2 Icons/VFD Logo.svg";

import { classNames } from "utils/lib/getClassName";
import CustomBankIcon from "assets/custom-icons/bank-icon";

const COLORMAPS = {
   FUNDING:
      "mb-[20px] flex w-full bg-accent-one items-center  gap-6 rounded-lg p-4 shadow-sm shadow-faintShadow-one",
   NORMAL:
      "mb-[20px] flex w-full bg-white items-center  gap-6 rounded-lg p-4 shadow-sm shadow-faintShadow-one",
};

const TEXTMAPS = {
   FUNDING: "text-white",
   NORMAL: "text-black-one",
};
const SUBMAPS = {
   FUNDING: "text-white",
   NORMAL: "text-black-two",
};

const AccountCard = ({
   bankName,
   accountNo,
   name,
   variant = "NORMAL",
   funding,
}) => {
   const bgClass = classNames(COLORMAPS[variant]);
   const textClass = classNames(TEXTMAPS[variant], "poppins-bold");
   const textSubClass = classNames(SUBMAPS[variant], "poppins-regular");

   return (
      <div className={bgClass}>
         {funding ? (
            <img src={VFDIcon} alt="bank account" className="md:h-[100px]" />
         ) : (
            <CustomBankIcon
               className="h-[50px] md:h-[100px]"
               color={"#1358CC"}
            />
         )}
         <div className="flex flex-col gap-1 text-xl md:text-2xl">
            <p className={textClass}>{bankName}</p>
            <p className={textSubClass}>{accountNo}</p>
            <p className={textSubClass}>{name}</p>
         </div>
      </div>
   );
};

export default AccountCard;
