import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { classNames } from "utils/lib/getClassName";

const VARIANT_MAPS = {
   PRIMARY: "absolute w-12  top-6 left-6 sm:left-16 md:hidden",
   SECONDARY: "absolute w-12  top-6 left-6 sm:left-16 md:left-40 lg:left-64",
   TERTIARY: "absolute w-12  top-6 left-6 sm:left-20 md:sticky -ml-[100px]",
   OTHER: "",
};

const COLOR_MAPS = {
   WHITE: "text-black-one md:text-secondary-darker",
   BLUE: "text-accent-one md:text-secondary-darker",
   GREY: "text-black-one",
};

export default function ArrowBack({
   to,
   handleBack,
   background,
   color = "BLUE",
   variant = "PRIMARY",
   first,
}) {
   const className = classNames(
      `self-start md:w-8 cursor-pointer text-4xl
       ${first && "hidden"}
      ${background === "BLUE" ? "text-white" : "text-accent-one"}`,
      COLOR_MAPS[color],
      VARIANT_MAPS[variant],
   );

   if (to) {
      return (
         <Link to={to} className={className}>
            <IoArrowBack onClick={handleBack} />
         </Link>
      );
   } else {
      return (
         <div className={className}>
            <IoArrowBack onClick={handleBack} />
         </div>
      );
   }
}
