import useStudent from "api/hooks/useStudent";
import { signOut } from "features/auth/auth.service";
import LinkItemColored from "features/settings/link-colored";
import Header from "features/shared/layout/headers/dashboard-header";
import useMember from "api/hooks/useStudent";
import SettingsFormColored from "features/settings/form-colored";
import CustomChangePinIcon from "assets/custom-icons/change-pin";
import CustomSetBankIcon from "assets/custom-icons/set-bank";
import CustomResetPasswordIcon from "assets/custom-icons/reset-password";
import CustomContactSupportIcon from "assets/custom-icons/contact-support";
import CustomLogOutIcon from "assets/custom-icons/logout";

export default function Settings() {
   //queries
   const { member } = useMember();

   //styles
   const iconClass = "mr-3 text-xl md:text-3xl";

   return (
      <section className="wrapper min-h-screen pb-[100px]">
         <Header title="Settings" variant={"SETTINGS"} />
         <hr className="mt-10 w-0" />
         <SettingsFormColored userData={member} />

         <div className="text-black mx-auto mb-[100px] h-full w-5/6 max-w-screen-sm">
            <p className="poppins-bold mb-[12px] mt-[40px] px-2 text-xl font-semibold md:text-2xl">
               Security
            </p>
            <div className="mb-7 flex  flex-col gap-3 rounded-lg bg-white p-5 shadow">
               <LinkItemColored
                  to="reset-password"
                  label="reset password"
                  icon={<CustomResetPasswordIcon className={"w-[24px]"} />}
               />

               <LinkItemColored
                  to="change-pin"
                  label="change transaction pin"
                  icon={<CustomChangePinIcon className={"w-[24px]"} />}
               />
               <LinkItemColored
                  label="set bank details"
                  icon={<CustomSetBankIcon className={"w-[24px]"} />}
                  to="bank-details"
               />
            </div>
            <div className="mx-auto max-w-screen-sm">
               <p className="poppins-bold mb-[12px] mt-[40px] px-2 text-xl font-semibold md:text-2xl">
                  Support
               </p>
               <div className="mb-7 flex  flex-col gap-3 rounded-lg bg-white p-5 shadow">
                  <LinkItemColored
                     to="contact-us"
                     label="contact support"
                     icon={<CustomContactSupportIcon className={"w-[24px]"} />}
                  />
               </div>
            </div>
            <div
               onClick={signOut}
               className="flex cursor-pointer flex-row items-center justify-center gap-[10px] text-black-one"
            >
               <CustomLogOutIcon className={"w-[24px]"} />
               <p className="poppins-medium text-[20px] font-bold text-black-one">
                  Logout
               </p>
            </div>
         </div>
      </section>
   );
}
