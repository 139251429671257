import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { MdOutlineCancel } from "react-icons/md";
import { BsHourglassSplit } from "react-icons/bs";

import useCountdownTimer from "../hooks/useTimer";
import useRequest from "utils/hooks/useRequest";
import { confirmFunding } from "../funding.service";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import useMember from "api/hooks/useStudent";

export default function useFundingActions(pendingFunding) {
   const navigate = useNavigate();
   const { mutate: mutateMember } = useMember();
   const { queryFn: actionFn } = useRequest(confirmFunding);
   const { showModal, hideModal, MODAL_TYPES } = useGlobalModalContext();

   useEffect(() => {
      if (!pendingFunding.reference) {
         navigate("/accounts/funding/create", { replace: true });
      }
   }, []);

   const [, accountNo, amount] = pendingFunding?.reference?.split("_") || [];

   const timeRemaining = () => {
      const date = new Date(pendingFunding?.expiration);
      return date.getTime();
   };

   const { timer, expired, resetExpired } = useCountdownTimer(timeRemaining());

   const completeHandleAction = () => {
      mutateMember();
      hideModal();
      navigate("/dashboard");
   };
   /*
    *handle Action essentially clears the existing transactionRef. It is
    *used to both confirm and cancel transaction.
    */
   const handleAction = (action) => {
      resetExpired();
      showModal(MODAL_TYPES.LOADING);
      actionFn("", {
         onSuccess: () => {
            hideModal();
            if (action === "confirm") {
               showModal(MODAL_TYPES.SUCCESS, {
                  icon: <BsHourglassSplit />,
                  title: "Confirming Funding ...",
                  btnText: "ok",
                  btnOnClick: completeHandleAction,
                  modalPosition: "CENTER",
                  message: `Your funding is being processed. \n
                     You will receive an email notification once it is completed`,
               });
            } else if (action === "cancel") {
               hideModal();
               showModal(MODAL_TYPES.SUCCESS, {
                  icon: <MdOutlineCancel />,
                  title: "Funding canceled successfully",
                  btnText: "ok",
                  modalPosition: "CENTER",
                  btnOnClick: completeHandleAction,
                  message:
                     "your funding transaction has been canceled successfully",
               });
            }
         },

         onError: () => {
            hideModal();
            showModal(MODAL_TYPES.ERROR, {
               message: "action cannot be processed at the moment",
            });
         },
      });
   };

   return { accountNo, amount, timer, expired, handleAction };
}
