import Sidebar from "features/ui/layout/nav/sidebar";
import { Link } from "react-router-dom";
export default function NotFound() {
   return (
      <div className="bg-[#FCFDFF] md:bg-[#EDF5FC]">
         <Sidebar />
         <div className="text-black mx-auto h-full min-h-screen w-5/6 max-w-screen-sm px-10 py-10 text-center">
            <h1 className="mb-6 text-4xl">Page Does Not Exist</h1>
            <p className="text-2xl">
               Sorry you've hit a resource that does not exist please return to
               the{" "}
               <Link to="/dashboard" className="text-primary underline">
                  dashboard
               </Link>
            </p>
         </div>
      </div>
   );
}
