import BankDetailsForm from "features/bank-details/components/bank-details-form";

import CardContainer from "features/ui/cards/card-container";
import EnterPassword from "features/bank-details/components/enter-password.component";
import PageTitle from "features/ui/layout/headers/page-title";
import ArrowBack from "features/ui/layout/nav/arrow-back";
import useUpdateBankDetails from "features/bank-details/api/update-bank-details.api";

export default function SetBankDetailsModal() {
   const {
      navigate,
      handleClick,
      state,
      dispatch,
      hookFormMethods,
      updateLoading,
      handleSubmit,
      enterPasswordModal,
      setEnterPasswordModal,
   } = useUpdateBankDetails();

   return (
      <>
         <CardContainer>
            <div className="container min-h-screen justify-center md:min-h-0 md:py-12">
               <ArrowBack handleBack={() => navigate(-1)} />
               <PageTitle
                  variant="TERTIARY"
                  title="Bank Details"
                  message="change payout account"
               />
               <BankDetailsForm
                  btnText="next"
                  onSubmit={handleClick}
                  hookFormMethods={hookFormMethods}
               />
            </div>
         </CardContainer>
         <EnterPassword
            state={state}
            dispatch={dispatch}
            loading={updateLoading}
            btnText="add account"
            handleSubmit={handleSubmit}
            isOpen={enterPasswordModal}
            onClose={() => setEnterPasswordModal(false)}
         />
      </>
   );
}
