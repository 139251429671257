import useMember from "api/hooks/useStudent";
import { requestPayout } from "api/services/students.service";
import useAllRequests from "features/requests/api/requests.api";
import Button from "features/shared/forms/button";
import WithdrawModals from "features/withdraw/withdraw-modal";
import React, { useState } from "react";

const Withdraw = () => {
   const [withdrawModal, setWithdrawModal] = useState(false);
   const [queryParams] = useState("");

   const { memberBankDetails, ledgerBalance } = useMember();

   const { mutateRequests } = useAllRequests(queryParams);

   return (
      <section className="wrapper">
         <div className="container-new-blue text-black mx-auto h-full min-h-screen w-5/6 max-w-screen-sm items-stretch py-10">
            <h2 className="poppins-bold mb-5 self-start text-xl md:text-4xl">
               Withdraw to account
            </h2>

            <p className="mb-10 text-base md:text-lg">
               Please Note that the withdraw process may require approval from
               your ledga admin. This may lead to additional delays in
               processing withdrawal. For more information, please contact your
               ledga admin.
            </p>

            <div className="mb-10 space-y-3">
               <h2 className="text-xl font-semibold">Details</h2>
               <DetailsField label="Available Bal" value={ledgerBalance} />
               <DetailsField
                  label="Bank Name"
                  value={memberBankDetails.bankName}
               />
               <DetailsField
                  label="Account Number"
                  value={memberBankDetails.accountName}
               />
               <DetailsField
                  label="Account Number"
                  value={memberBankDetails.accountNumber}
               />
            </div>

            <div className="mx-auto">
               <Button size="WIDE" onClick={() => setWithdrawModal(true)}>
                  Continue
               </Button>
            </div>
            <WithdrawModals
               modalDisplay={withdrawModal}
               setModalDisplay={setWithdrawModal}
               mutateFn={mutateRequests}
               withdrawService={requestPayout}
               balance={ledgerBalance}
               bankDetails={memberBankDetails}
            />
         </div>
      </section>
   );
};

export default Withdraw;

const DetailsField = ({ label, value }) => {
   return (
      <div className="flex">
         <p className="basis-[180px] font-semibold text-black-two">{label}:</p>
         <p>{value}</p>
      </div>
   );
};
