import React from "react";

const CustomHomeIcon = ({ className, color = "#1358CC" }) => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 87.42 93.15"
         className={className}
      >
         {" "}
         <defs>
            <style>{`.cls-home{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  class="cls-home"
                  d="M54.53,93.15a4.08,4.08,0,0,1-3.86-5.42c.28-2,0-10.64-.41-17.8a1.93,1.93,0,0,1,0-.24,7.32,7.32,0,0,0-2.16-5.21,4.47,4.47,0,0,0-.45-.42,7.37,7.37,0,0,0-9.49,0,7.38,7.38,0,0,0-2.63,5.65,1.62,1.62,0,0,1,0,.22c-.39,7-.62,15.49-.34,17.41a4.08,4.08,0,0,1-3.86,5.44H19.46A19.48,19.48,0,0,1,0,73.3V44.73a27.21,27.21,0,0,1,9.2-20.4L32.18,4a16.15,16.15,0,0,1,21.4,0l8.76,7.77A4.09,4.09,0,1,1,56.91,18l-8.76-7.78a8,8,0,0,0-10.55,0l-23,20.28A19,19,0,0,0,8.18,44.73V73.3A11.29,11.29,0,0,0,19.46,84.58h7.42c-.05-3,.06-7.7.46-15A15.54,15.54,0,0,1,52.88,57.79c.33.27.67.58,1,.9a15.51,15.51,0,0,1,4.55,10.87c.43,7.44.56,12.2.53,15.29l8.88-.27H68A11.29,11.29,0,0,0,79.24,73.3V44.08A14,14,0,0,0,74.53,33.6l-5.94-5.28A4.09,4.09,0,1,1,74,22.21L80,27.49a22.2,22.2,0,0,1,7.46,16.59V73.3A19.48,19.48,0,0,1,68,92.76l-13.36.39Zm-3.88-5.29h0Zm.28-.72h0Zm-16-.39h0Z"
               />
            </g>
         </g>
      </svg>
   );
};

export default CustomHomeIcon;
