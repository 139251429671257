import React from "react";

const CustomPayBillIcon = ({ className, color }) => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 90.93 90.37"
         className={className}
      >
         <defs>
            <style>{`.cls-payBill{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <rect
                  class="cls-payBill"
                  x="16.52"
                  y="28.32"
                  width="30.83"
                  height="7.6"
                  rx="3.8"
               />
               <rect
                  class="cls-payBill"
                  x="16.52"
                  y="45.52"
                  width="57.34"
                  height="7.6"
                  rx="3.8"
               />
               <rect
                  class="cls-payBill"
                  x="16.52"
                  y="62.72"
                  width="57.34"
                  height="7.6"
                  rx="3.8"
               />
               <path
                  class="cls-payBill"
                  d="M60.87,36.21a4.09,4.09,0,0,1-2.86-7L84,3.79A4.09,4.09,0,0,1,89.7,9.63L63.73,35A4.07,4.07,0,0,1,60.87,36.21Z"
               />
               <path
                  class="cls-payBill"
                  d="M72.7,36.21H60.87a4.09,4.09,0,0,1-4.09-4.09V20.5a4.09,4.09,0,1,1,8.18,0V28H72.7a4.09,4.09,0,1,1,0,8.18Z"
               />
               <path
                  class="cls-payBill"
                  d="M66,90.37H24.41A24.44,24.44,0,0,1,0,66V24.41A24.44,24.44,0,0,1,24.41,0H66a4.55,4.55,0,0,1,0,9.09H24.41A15.33,15.33,0,0,0,9.09,24.41V66A15.34,15.34,0,0,0,24.41,81.29H66A15.35,15.35,0,0,0,81.29,66V24.41a4.54,4.54,0,0,1,9.08,0V66A24.44,24.44,0,0,1,66,90.37Z"
               />
            </g>
         </g>
      </svg>
   );
};

export default CustomPayBillIcon;
