import Button from "features/ui/forms/button";
import ModalContainer from "features/modal/modal-container.component";
import useFundingActions from "../api/funding-actions.api";
import { getFormattedAmount } from "utils/lib/number-formatter";

export default function FundingExistsModal({
   handleCreate,
   onClose,
   isOpen,
   pendingFunding,
}) {
   const { handleAction } = useFundingActions(pendingFunding);

   const handleCancel = async () => {
      handleAction();
      handleCreate();
   };

   return (
      <ModalContainer
         isOpen={isOpen}
         onClose={onClose}
         modalPosition="CENTER"
         size="THIN"
      >
         <div className="container min-h-screen justify-center text-center text-lg md:min-h-0 md:py-20">
            <section>
               <h2 className="text-2xl font-semibold md:text-xl">
                  Funding already Exists
               </h2>
               <p className="mb-6  text-black-one">
                  A funding transaction already exists for{" "}
                  <span className="font-semibold text-primary">
                     {getFormattedAmount(
                        pendingFunding?.reference?.split("_")[2],
                     )}
                  </span>
                  . Creating a new transaction will cancel the funding reference
               </p>
               <div className="flex justify-center gap-2">
                  <Button onClick={handleCancel}>Create New</Button>
                  <Button to="/accounts/funding/actions">View Pending</Button>
               </div>
            </section>
         </div>
      </ModalContainer>
   );
}
