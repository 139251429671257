import React from "react";

const CustomPayMerchantIcon = ({ className, color }) => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 81.29 91.99"
         className={className}
      >
         <defs>
            <style>{`.cls-payMerchant{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  class="cls-payMerchant"
                  d="M4.54,90.33A4.54,4.54,0,0,1,0,85.79C0,64.63,17.48,47.41,39,47.41A39.19,39.19,0,0,1,60.73,54a4.54,4.54,0,1,1-5,7.57A30.18,30.18,0,0,0,39,56.5C22.49,56.5,9.09,69.64,9.09,85.79A4.55,4.55,0,0,1,4.54,90.33Z"
               />
               <path
                  class="cls-payMerchant"
                  d="M39,49.6a24.8,24.8,0,1,1,24.8-24.8A24.82,24.82,0,0,1,39,49.6ZM39,9.09A15.72,15.72,0,1,0,54.68,24.8,15.73,15.73,0,0,0,39,9.09Z"
               />
               <path
                  class="cls-payMerchant"
                  d="M74.59,89.42v1A1.54,1.54,0,0,1,73.06,92H70.8a1.54,1.54,0,0,1-1.53-1.53v-.95A1.51,1.51,0,0,0,68,88q-5.11-1-6.95-5a1.53,1.53,0,0,1,1.06-2.13l2.49-.57a1.57,1.57,0,0,1,1.77.77q1.24,2.46,4.77,2.46a5,5,0,0,0,3.06-.78,2.33,2.33,0,0,0,1-1.88,2,2,0,0,0-.9-1.75A10.73,10.73,0,0,0,70.8,78q-5.7-1.17-7.35-3.17a7.07,7.07,0,0,1-1.66-4.67,7.55,7.55,0,0,1,2.09-5.35,8.32,8.32,0,0,1,4.22-2.41,1.52,1.52,0,0,0,1.17-1.48v-.36A1.53,1.53,0,0,1,70.8,59h1.84a1.53,1.53,0,0,1,1.53,1.53v.41a1.55,1.55,0,0,0,1.07,1.45,9.64,9.64,0,0,1,4.54,3.91A1.54,1.54,0,0,1,79,68.56l-2.16.72A1.52,1.52,0,0,1,75,68.64a4.17,4.17,0,0,0-3.86-1.94c-2.35,0-3.53.76-3.53,2.28a2.07,2.07,0,0,0,.66,1.56,6.69,6.69,0,0,0,3,1.09A29.16,29.16,0,0,1,77,73.22a7.2,7.2,0,0,1,3,2.59,6.88,6.88,0,0,1,1.24,4c0,5.72-3.7,7.58-5.61,8.15A1.53,1.53,0,0,0,74.59,89.42Z"
               />
            </g>
         </g>
      </svg>
   );
};

export default CustomPayMerchantIcon;
