import React from "react";

const CustomLogOutIcon = ({ className, color = "#000000" }) => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 78.82 83.08"
         className={className}
      >
         <defs>
            <style>{`.cls-logout{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  class="cls-logout"
                  d="M39.41,83.08A39.41,39.41,0,0,1,10.73,16.64,5,5,0,1,1,18,23.5a29.41,29.41,0,1,0,42.82,0,5,5,0,1,1,7.27-6.86A39.41,39.41,0,0,1,39.41,83.08Z"
               />
               <path
                  class="cls-logout"
                  d="M39.41,41.35a5,5,0,0,1-5-5V5a5,5,0,0,1,10,0V36.35A5,5,0,0,1,39.41,41.35Z"
               />
            </g>
         </g>
      </svg>
   );
};

export default CustomLogOutIcon;
