import { classNames } from "utils/lib/getClassName";

const VARIANT_MAPS = {
   FULL_SCREEN: "min-h-full w-full md:min-h-0 md:rounded-xl",
   NUMPAD:
      "min-h-full w-full md:min-h-0 md:rounded-xl flex flex-col items-center justify-center",
   AUTO: "w-5/6",
};
const AREA_MAPS = {
   THIN: "md:max-w-[430px]",
   MEDIUM: "md:max-w-[550px] lg:max-w-screen-sm",
   LARGE: "md:max-w-[550px] lg:max-w-screen-md",
};
const COLOR_MAPS = (variant) => {
   return {
      BLUE: `bg-primary text-white ${
         variant === "NUMPAD" && "md:bg-white md:text-secondary"
      }`,
      WHITE: `bg-white text-primary ${
         variant === "NUMPAD" && "md:bg-white md:text-secondary"
      }`,
   };
};

const POSITION_MAPS = {
   CENTER: " ",
   RIGHT: "md:-translate-x-[calc(50%-var(--sidebar-width-base)/2)] lg:-translate-x-[calc(50%-var(--sidebar-width)/2)]",
};

export default function CardContainer({
   children,
   variant = "FULL_SCREEN",
   area = "MEDIUM",
   color = "WHITE",
   position = "CENTER",
}) {
   //z-index on smaller screen hides the sidebar menu
   const cardContainer = "fixed z-20 top-0 left-0 w-full h-full md:z-0";

   const wrapper = classNames(
      "absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 border-2 border-gray overflow-hidden flex items-center",
      VARIANT_MAPS[variant],
      AREA_MAPS[area],
      COLOR_MAPS(variant)[color],
      POSITION_MAPS[position],
   );

   return (
      <div className={cardContainer}>
         <div tabIndex={1} className={wrapper}>
            {children}
         </div>
      </div>
   );
}
