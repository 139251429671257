import React from "react";

const CustomSetBankIcon = ({ className, color = "#1358CC" }) => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 73.18 80.56"
         className={className}
      >
         <defs>
            <style>{`.cls-bank{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  class="cls-bank"
                  d="M66.61,39.89H4.54a4.54,4.54,0,0,1-3.2-7.76l31-30.81a4.55,4.55,0,0,1,6.41,0L46,8.53A4.54,4.54,0,0,1,39.64,15l-4.06-4-20,19.87h40l-6-6a4.54,4.54,0,0,1,6.4-6.45L69.81,32.13a4.54,4.54,0,0,1-3.2,7.76Z"
               />
               <path
                  class="cls-bank"
                  d="M68.64,80.56H4.54a4.55,4.55,0,0,1,0-9.09h64.1a4.55,4.55,0,0,1,0,9.09Z"
               />
               <path
                  class="cls-bank"
                  d="M56,80.56A4.54,4.54,0,0,1,51.43,76V35.35a4.54,4.54,0,1,1,9.08,0V76A4.54,4.54,0,0,1,56,80.56Z"
               />
               <path
                  class="cls-bank"
                  d="M36.84,80.56A4.54,4.54,0,0,1,32.3,76V35.35a4.55,4.55,0,0,1,9.09,0V76A4.55,4.55,0,0,1,36.84,80.56Z"
               />
               <path
                  class="cls-bank"
                  d="M17.72,80.56A4.55,4.55,0,0,1,13.17,76V36.55a4.55,4.55,0,0,1,9.09,0V76A4.54,4.54,0,0,1,17.72,80.56Z"
               />
            </g>
         </g>
      </svg>
   );
};

export default CustomSetBankIcon;
