import React from "react";

const CustomResetPasswordIcon = ({ className, color = "#1358CC" }) => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 95.64 108.08"
         className={className}
      >
         <defs>
            <style>{`.cls-reset{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  class="cls-reset"
                  d="M65.07,80.51H40.54A10.26,10.26,0,0,1,30.29,70.26V49.83A10.26,10.26,0,0,1,40.54,39.58H65.07A10.27,10.27,0,0,1,75.33,49.83V60.44a4.09,4.09,0,0,1-8.18,0V49.83a2.08,2.08,0,0,0-2.08-2.07H40.54a2.07,2.07,0,0,0-2.07,2.07V70.26a2.07,2.07,0,0,0,2.07,2.08H65.07a4.09,4.09,0,1,1,0,8.17Z"
               />
               <path
                  class="cls-reset"
                  d="M61.82,46.8a4.09,4.09,0,0,1-4.09-4.09v-5.9A2.78,2.78,0,0,0,55,34H51.12a2.78,2.78,0,0,0-2.77,2.78v5.9a4.09,4.09,0,0,1-8.18,0v-5.9a11,11,0,0,1,10.95-11H55a11,11,0,0,1,10.95,11v5.9A4.09,4.09,0,0,1,61.82,46.8Z"
               />
               <path
                  class="cls-reset"
                  d="M52.77,108.08A52.77,52.77,0,1,1,68.46,4.92,40.94,40.94,0,0,1,78,9.36c-.42-.84-.89-1.74-1.39-2.71a4.54,4.54,0,0,1,8-4.21c7,13.32,9.17,19.39,4.79,22.49-3.57,2.51-6.86-.35-9.5-2.66-3-2.63-7.58-6.6-14.23-8.67A43.69,43.69,0,1,0,87.49,81.82a4.54,4.54,0,0,1,7.22,5.52A52.42,52.42,0,0,1,52.77,108.08Z"
               />
            </g>
         </g>
      </svg>
   );
};

export default CustomResetPasswordIcon;
