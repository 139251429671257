import TableRow from "features/ui/tables/table-row";
import TableCol from "features/ui/tables/table-col";
import { BsBank2, BsBoxArrowInDown } from "react-icons/bs";
import { useGlobalModalContext } from "features/modal/global-modal.context";
import CustomCreditIcon from "assets/custom-icons/credit-icon";
import CustomDebitIcon from "assets/custom-icons/debit-icon";
import CustomIntraIcon from "assets/custom-icons/intra-icon";

export default function TransactionsRow({ day }) {
   const { showModal, MODAL_TYPES } = useGlobalModalContext();
   const showReceiptModal = (transaction) => {
      showModal(MODAL_TYPES.RECEIPT, {
         id: transaction._id,
         amount: transaction.amount,
         fullAmount: transaction.orginalAmount,
         created: transaction.created,
         type: transaction.payment_type,
         reference: transaction.transaction_reference,
         sender: transaction.sender,
         recipient: transaction.recipient,
      });
   };

   return (
      <>
         <div className="poppins-regular mb-6 md:mb-12">
            <div key={day._id}>
               <div onClick={() => showReceiptModal(day)}>
                  <TableRow
                     gridTemplateCols="grid-cols-4"
                     className="cursor-pointer hover:bg-blue-50"
                  >
                     <div className="col-span-2 flex items-center gap-[10px]">
                        {/* <div className="bg-gray-200 h-[40px] w-[40px] rounded-full"></div> */}

                        <BsBank2 />
                        <TableCol className="text-black flex flex-col items-start font-medium">
                           <p className="text-[14px]">
                              {day?.payment_type === "CREDIT"
                                 ? day?.from
                                 : day?.payment_type === "DEBIT"
                                 ? day?.to
                                 : day?.payment_type === "INTERNAL"
                                 ? day?.to
                                 : day?.to}{" "}
                           </p>
                           <p className="poppins-regular w-full text-left text-[10px] leading-tight text-black-three">
                              {day?.formattedTime}
                           </p>
                        </TableCol>
                     </div>

                     <TableCol className="justify-center font-medium text-secondary-darker">
                        {day.payment_type === "CREDIT" && (
                           <CustomCreditIcon
                              className={"w-[15px]"}
                              color={"#86efac"}
                           />
                        )}
                        {day.payment_type === "DEBIT" && (
                           <CustomDebitIcon
                              className={"w-[15px]"}
                              color={"#DC2626"}
                           />
                        )}
                        {day.payment_type === "INTERNAL" && (
                           <CustomIntraIcon
                              className={"w-[15px]"}
                              color={"#0A0A0A"}
                           />
                        )}
                     </TableCol>
                     <TableCol className={`text-black justify-end font-bold`}>
                        <span className="text-xs">₦</span>
                        {day.formattedAmount.slice(1)}
                     </TableCol>
                  </TableRow>
               </div>
            </div>
         </div>
      </>
   );
}
