import { useState } from "react";
import useRequest from "utils/hooks/useRequest";

import useStudent from "api/hooks/useStudent";
import NumPadModal from "features/numpad/numpad-modal.component";
import LoadingModal from "features/shared/feedbacks/loading-modal";
import { createTransactionPin } from "api/services/settings.service";
import SuccessFeedbackModal from "features/shared/feedbacks/success-modal";
import FailModal from "features/shared/feedbacks/failure-modal";

export default function CreatePinModal({
   modalDisplay,
   setModalDisplay,
   modalPosition,
   btnText,
   successBtnOnClick,
}) {
   //TODO: error message will be needed too
   //form states
   const [pin, setPin] = useState("");
   const [confirmationPin, setConfirmationPin] = useState("");

   //modal states
   const [successModal, setSuccessModal] = useState(false);
   const [failureModal, setFailureModal] = useState(false);
   const [confirmPinModal, setConfirmPinModal] = useState(false);

   //constants
   const pinIsValid = /\d{4}/.test(pin);

   //query functions
   const {
      queryFn: createPinFn,
      loading,
      error,
   } = useRequest(createTransactionPin);

   //handle functions
   const handleNext = () => {
      if (pinIsValid) {
         setModalDisplay(false);
         setConfirmPinModal(true);
      }
   };

   const { mutate } = useStudent();

   const handleSubmit = () => {
      if (pin === confirmationPin) {
         setPin("");
         setConfirmationPin("");
         setConfirmPinModal(false);
         const queryObj = { pin };
         createPinFn(queryObj, {
            onSuccess: () => {
               /*mutate student so the hasSetTransactionPin can be 
               refreshed in cache*/
               mutate();
               setConfirmPinModal(false);
               setSuccessModal(true);
            },
            onError: () => {
               setFailureModal(true);
            },
         });
      }
   };

   const handleError = () => {
      setModalDisplay(false);
      setFailureModal(true);
   };

   const handleFinish = () => {
      if (successBtnOnClick) {
         successBtnOnClick();
      }
      setSuccessModal(false);
   };

   return (
      <>
         <NumPadModal
            authNumpad
            modalPosition={modalPosition}
            pin={pin}
            setPin={setPin}
            title={`create transaction pin`}
            btnText="proceed"
            btnOnClick={handleNext}
            modalDisplay={modalDisplay}
            message={`Set up your transaction pin for approving \n requests on your ledga`}
         />
         <NumPadModal
            authNumpad
            modalPosition={modalPosition}
            pin={confirmationPin}
            setPin={setConfirmationPin}
            title="confirm pin"
            message="input newly created pin"
            btnText="proceed"
            btnOnClick={handleSubmit}
            modalDisplay={confirmPinModal}
         />
         <LoadingModal
            modalPosition={modalPosition}
            modalDisplay={loading}
            message="please wait while the pin is being set"
         />
         <SuccessFeedbackModal
            modalPosition={modalPosition}
            modalDisplay={successModal}
            src="/icons/arcticons_ok-payment.svg"
            title="pin created"
            message="You can now approve transactions securely"
            btnText={btnText}
            btnOnClick={handleFinish}
            outsideOnClick={() => setSuccessModal((prev) => !prev)}
            toggleModal={() => setSuccessModal((prev) => !prev)}
         />
         <FailModal
            title="pin creation failed"
            message={error}
            modalDisplay={failureModal}
            modalPosition={modalPosition}
            btnText="try again"
            btnOnClick={handleError}
         />
      </>
   );
}
