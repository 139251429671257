import React from "react";

const CustomContactSupportIcon = ({ className, color = "#1358CC" }) => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 88.2 95.87"
         className={className}
      >
         <defs>
            <style>{`.cls-contactSupport{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  class="cls-contactSupport"
                  d="M74.59,95.87a4.55,4.55,0,0,1-4.54-4.55c0-14.07-11.69-25.53-26.06-25.53S17.93,77.25,17.93,91.32a4.55,4.55,0,1,1-9.09,0C8.84,72.24,24.61,56.71,44,56.71S79.14,72.24,79.14,91.32A4.55,4.55,0,0,1,74.59,95.87Z"
               />
               <path
                  class="cls-contactSupport"
                  d="M44,59.66A22.56,22.56,0,1,1,66.54,37.11,22.57,22.57,0,0,1,44,59.66Zm0-36A13.47,13.47,0,1,0,57.46,37.11,13.48,13.48,0,0,0,44,23.64Z"
               />
               <path
                  class="cls-contactSupport"
                  d="M78,42.54A4.54,4.54,0,0,1,73.49,38C73.49,22.06,60.26,9.09,44,9.09s-29.5,13-29.5,28.91A4.55,4.55,0,0,1,5.4,38C5.4,17.05,22.71,0,44,0S82.58,17.05,82.58,38A4.55,4.55,0,0,1,78,42.54Z"
               />
               <path
                  class="cls-contactSupport"
                  d="M10.17,54.68A10.17,10.17,0,1,1,20.33,44.52,10.18,10.18,0,0,1,10.17,54.68Zm0-11.24a1.08,1.08,0,0,0,0,2.16,1.08,1.08,0,1,0,0-2.16Z"
               />
               <path
                  class="cls-contactSupport"
                  d="M78,53.79A10.17,10.17,0,1,1,88.2,43.62,10.19,10.19,0,0,1,78,53.79Zm0-11.25a1.08,1.08,0,1,0,0,2.16,1.08,1.08,0,1,0,0-2.16Z"
               />
            </g>
         </g>
      </svg>
   );
};

export default CustomContactSupportIcon;
