import React from "react";

const CustomChangePinIcon = ({ className, color = "#1358CC" }) => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 79.84 98.31"
         className={className}
      >
         <defs>
            <style>{`.cls-pin{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  class="cls-pin"
                  d="M63.47,98.31H16.37A16.39,16.39,0,0,1,0,81.93V42.71A16.39,16.39,0,0,1,16.37,26.34h47.1A16.39,16.39,0,0,1,79.84,42.71V63.07a4.54,4.54,0,1,1-9.08,0V42.71a7.3,7.3,0,0,0-7.29-7.28H16.37a7.29,7.29,0,0,0-7.28,7.28V81.93a7.29,7.29,0,0,0,7.28,7.29h47.1a4.55,4.55,0,0,1,0,9.09Z"
               />
               <path
                  class="cls-pin"
                  d="M57.23,33.59A4.55,4.55,0,0,1,52.68,29V17.71a8.64,8.64,0,0,0-8.63-8.62H36.69a8.64,8.64,0,0,0-8.63,8.62V29A4.54,4.54,0,1,1,19,29V17.71A17.73,17.73,0,0,1,36.69,0h7.36A17.74,17.74,0,0,1,61.77,17.71V29A4.55,4.55,0,0,1,57.23,33.59Z"
               />
               <path
                  class="cls-pin"
                  d="M23.23,61.06h0a1.12,1.12,0,0,1,1.41-.72l3,1a1.12,1.12,0,0,1,.72,1.41h0a1.11,1.11,0,0,1-1.41.72l-3-1A1.13,1.13,0,0,1,23.23,61.06Zm1.7,4.46L26.78,63a1.13,1.13,0,0,1,1.57-.25h0a1.12,1.12,0,0,1,.25,1.56l-1.85,2.55a1.12,1.12,0,0,1-1.56.25h0A1.12,1.12,0,0,1,24.93,65.52Zm3.42-8.18h0a1.12,1.12,0,0,1,1.12,1.11v3.16a1.12,1.12,0,0,1-1.12,1.11h0a1.12,1.12,0,0,1-1.12-1.11V58.45A1.12,1.12,0,0,1,28.35,57.34Zm0,5.39h0a1.12,1.12,0,0,1,1.56.25l1.86,2.55a1.12,1.12,0,0,1-.25,1.56h0A1.12,1.12,0,0,1,30,66.84L28.1,64.29A1.12,1.12,0,0,1,28.34,62.73Zm.73-1.42,3-1a1.12,1.12,0,0,1,1.41.72h0a1.13,1.13,0,0,1-.72,1.41l-3,1a1.11,1.11,0,0,1-1.41-.72h0A1.12,1.12,0,0,1,29.07,61.31Z"
               />
               <path
                  class="cls-pin"
                  d="M36.42,60.79h0a1.12,1.12,0,0,1,1.41-.71l3,1a1.12,1.12,0,0,1,.72,1.4h0a1.12,1.12,0,0,1-1.41.72l-3-1A1.12,1.12,0,0,1,36.42,60.79Zm1.71,4.47L40,62.71a1.11,1.11,0,0,1,1.56-.25h0A1.13,1.13,0,0,1,41.8,64l-1.86,2.55a1.11,1.11,0,0,1-1.56.24h0A1.11,1.11,0,0,1,38.13,65.26Zm3.41-8.19h0a1.12,1.12,0,0,1,1.12,1.12v3.15a1.12,1.12,0,0,1-1.12,1.12h0a1.12,1.12,0,0,1-1.11-1.12V58.19A1.12,1.12,0,0,1,41.54,57.07Zm0,5.39h0a1.11,1.11,0,0,1,1.56.25L45,65.26a1.13,1.13,0,0,1-.24,1.56h0a1.12,1.12,0,0,1-1.57-.24L41.29,64A1.13,1.13,0,0,1,41.54,62.46Zm.72-1.41,3-1a1.12,1.12,0,0,1,1.41.71h0A1.12,1.12,0,0,1,46,62.2l-3,1a1.13,1.13,0,0,1-1.42-.72h0A1.11,1.11,0,0,1,42.26,61.05Z"
               />
               <path
                  class="cls-pin"
                  d="M48.38,60.63h0a1.13,1.13,0,0,1,1.41-.72l3,1a1.12,1.12,0,0,1,.72,1.41h0a1.13,1.13,0,0,1-1.42.72l-3-1A1.13,1.13,0,0,1,48.38,60.63Zm1.71,4.47,1.85-2.56a1.11,1.11,0,0,1,1.56-.24h0a1.12,1.12,0,0,1,.25,1.56l-1.85,2.55a1.12,1.12,0,0,1-1.57.25h0A1.12,1.12,0,0,1,50.09,65.1Zm3.42-8.19h0A1.12,1.12,0,0,1,54.63,58v3.15a1.12,1.12,0,0,1-1.12,1.12h0a1.12,1.12,0,0,1-1.12-1.12V58A1.12,1.12,0,0,1,53.51,56.91Zm0,5.39h0a1.11,1.11,0,0,1,1.56.24l1.85,2.56a1.11,1.11,0,0,1-.24,1.56h0a1.11,1.11,0,0,1-1.56-.25l-1.86-2.55A1.12,1.12,0,0,1,53.5,62.3Zm.72-1.42,3-1a1.12,1.12,0,0,1,1.41.72h0A1.12,1.12,0,0,1,57.91,62l-3,1a1.12,1.12,0,0,1-1.41-.72h0A1.13,1.13,0,0,1,54.22,60.88Z"
               />
            </g>
         </g>
      </svg>
   );
};

export default CustomChangePinIcon;
