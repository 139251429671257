export default function CustomCreditIcon({ className, color }) {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 86.09 86.09"
         className={className}
      >
         <defs>
            <style>{`.cls-credit{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  class="cls-credit"
                  d="M43.05,86.09a43.09,43.09,0,0,1-43-43,5,5,0,1,1,10,0A33,33,0,1,0,43.05,10a5,5,0,1,1,0-10,43,43,0,0,1,0,86.09Z"
               />
               <path
                  class="cls-credit"
                  d="M37.37,59.36a5,5,0,0,1-.53-10l4.9-.53L14.5,21.11a5,5,0,1,1,7.14-7L56.11,49.22a5,5,0,0,1-3,8.48L37.91,59.33A4.89,4.89,0,0,1,37.37,59.36Z"
               />
               <path
                  class="cls-credit"
                  d="M53.14,57.66l-.53,0a5,5,0,0,1-4.45-5.5l1.5-14.42a5,5,0,1,1,10,1l-1.5,14.43A5,5,0,0,1,53.14,57.66Z"
               />
            </g>
         </g>
      </svg>
   );
}
