import useSWR from "swr";
import { useState, useEffect } from "react";

import { getMemberUrl } from "features/auth/auth.service";
import { getFormattedAmount } from "utils/lib/number-formatter";

export default function useMember(config) {
   const [memberBankDetails, setMemberBankDetails] = useState({});
   const [memberId, setMemberId] = useState("");
   const [ledgerBalance, setLedgerBalance] = useState("");

   const {
      data: member,
      error: getMemberError,
      mutate,
   } = useSWR(getMemberUrl, config);

   useEffect(() => {
      if (member?.bank_details) {
         const {
            bank_name: bankName,
            account_name: accountName,
            account_number: accountNumber,
         } = member.bank_details;

         setMemberId(member.unique_id);
         setMemberBankDetails({ bankName, accountName, accountNumber });
         setLedgerBalance(getFormattedAmount(member.meta.balance));
      }
   }, [member]);

   const getMemberLoading = !member && !getMemberError;
   return {
      memberId,
      member,
      ledgerBalance,
      memberBankDetails,
      getMemberError,
      getMemberLoading,
      mutate,
      pendingFunding: {
         reference: member?.meta?.funding?.pending_transaction_reference,
         expiration: member?.meta?.funding?.expiration_time,
      },
   };
}
