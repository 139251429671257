import React from "react";

const CustomSendFriendIcon = ({ className, color }) => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 79.6 89.4"
         className={className}
      >
         <defs>
            <style>{`.cls-sendFriend{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  class="cls-sendFriend"
                  d="M4.54,85.72A4.54,4.54,0,0,1,0,81.18C0,60.59,17,43.84,37.91,43.84A38.12,38.12,0,0,1,59.08,50.2a4.54,4.54,0,1,1-5,7.57,29.08,29.08,0,0,0-16.15-4.84C22,52.93,9.09,65.6,9.09,81.18A4.55,4.55,0,0,1,4.54,85.72Z"
               />
               <path
                  class="cls-sendFriend"
                  d="M37.91,48.36A24.18,24.18,0,1,1,62.09,24.18,24.21,24.21,0,0,1,37.91,48.36Zm0-39.27A15.1,15.1,0,1,0,53,24.18,15.11,15.11,0,0,0,37.91,9.09Z"
               />
               <path
                  class="cls-sendFriend"
                  d="M75.06,78.92h-37a4.55,4.55,0,0,1,0-9.09H65.19l-2.77-3.24a4.54,4.54,0,1,1,6.9-5.91l9.19,10.74a4.55,4.55,0,0,1-3.45,7.5Z"
               />
               <path
                  class="cls-sendFriend"
                  d="M66.71,89.4A4.55,4.55,0,0,1,63.15,82L71.5,71.54a4.54,4.54,0,0,1,7.11,5.66L70.26,87.69A4.51,4.51,0,0,1,66.71,89.4Z"
               />
            </g>
         </g>
      </svg>
   );
};

export default CustomSendFriendIcon;
