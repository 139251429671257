import { axios } from "utils/lib/axios";

//transactions
export const getTransactionsUrl = "member/new-transactions";

export const getAllRequestsUrl = "member/payouts/requests";

export const cancelPayoutRequest = async (queryObj) => {
   return axios.put("member/payouts/request/cancel", queryObj);
};
