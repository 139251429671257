import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "features/ui/layout/nav/sidebar";
import SidebarRight from "features/ui/layout/nav/sidebar-right";

export default function Dashboard() {
   return (
      <Fragment>
         <div className="bg-[#FCFDFF] md:bg-[#EDF5FC]">
            <Sidebar />
            <Outlet />
            <SidebarRight />
         </div>
      </Fragment>
   );
}
