export default function CustomNotificationIcon({ className, color }) {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 73.75 92.41"
         className={className}
      >
         <defs>
            <style>{`.cls-notification{fill:${color};}`}</style>
         </defs>
         <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
               <path
                  class="cls-notification"
                  d="M57.65,78.26H15.33c-4,0-9.68-2.58-13.17-8.34S-.53,55.46,3.82,50.35C8,45.41,7.18,41,6.28,36.36a29.2,29.2,0,0,1-.71-5.55A30.84,30.84,0,0,1,36.38,0,32.53,32.53,0,0,1,60.26,10.62,26.36,26.36,0,0,1,67.17,31.2c-1.15,10.56.67,13.84,3,18,.67,1.2,1.35,2.43,2,3.89,4.13,9.09,0,19.59-9.44,23.91A12.33,12.33,0,0,1,57.65,78.26ZM36.38,7.15A23.68,23.68,0,0,0,12.72,30.81,24.74,24.74,0,0,0,13.3,35c1,5.24,2.39,12.43-4,20-2.38,2.79-2.85,8.14-1,11.23,2.15,3.56,5.47,4.89,7.05,4.89H57.65a5.54,5.54,0,0,0,2.08-.63c5.8-2.65,8.39-9,5.91-14.46-.54-1.19-1.13-2.25-1.75-3.36-2.57-4.63-5.23-9.41-3.83-22.24h0a19.24,19.24,0,0,0-5.12-15A25.26,25.26,0,0,0,36.38,7.15Z"
               />
               <path
                  class="cls-notification"
                  d="M36.87,92.41C26,92.41,17.1,84.75,17.1,75.33h7.15c0,5.48,5.66,9.93,12.62,9.93s12.62-4.45,12.62-9.93h7.15C56.64,84.75,47.77,92.41,36.87,92.41Z"
               />
            </g>
         </g>
      </svg>
   );
}
