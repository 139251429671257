import { useState } from "react";

import NavItem from "./nav-item";
import { signOut } from "features/auth/auth.service";

import { ReactComponent as LogoutIcon } from "../../../../assets/icons/LedgaV2 Icons/Logout Final.svg";

import { ReactComponent as HistoryIcon } from "../../../../assets/icons/LedgaV2 Icons/Swap.svg";
import { ReactComponent as HistoryFilledIcon } from "../../../../assets/icons/LedgaV2 Icons/Swap White.svg";
import { ReactComponent as HistoryBlueIcon } from "../../../../assets/icons/LedgaV2 Icons/Swap Blue.svg";
import CustomHomeIcon from "assets/custom-icons/home-icon";
import CustomBillIcon from "assets/custom-icons/bill-icon";
import CustomSettingsIcon from "assets/custom-icons/settings";
import CustomLogOutIcon from "assets/custom-icons/logout";
import CustomHistoryIcon from "assets/custom-icons/history";

export default function Sidebar() {
   const navItemClassName = "text-2xl md:text-xl mx-auto";
   const [activeLabel, setActiveLabel] = useState("");
   const handleClick = (label) => setActiveLabel(label);

   return (
      <aside className="fixed bottom-0 z-10 flex w-full justify-between bg-white px-2 transition  dark:border-none dark:bg-[white] md:top-0 md:block md:h-screen md:w-[100px] md:p-6 md:px-3 lg:w-[200px]">
         <div className="flex w-[100%] flex-row justify-between md:h-full md:flex-col md:justify-center">
            <NavItem
               label="Overview"
               to="/dashboard"
               isActive={activeLabel === "Home"}
               onClick={() => handleClick("Home")}
               icon={<CustomHomeIcon className={"w-[25px]"} color="#7E7E7E" />}
               activeIcon={
                  <CustomHomeIcon className={"w-[25px]"} color={"#FFFFFF"} />
               }
               smallIcon={<CustomHomeIcon className={"w-[25px]"} />}
            />

            <NavItem
               label="Accounts"
               isActive={activeLabel === "Accounts"}
               to="/dashboard/accounts/active"
               icon={<CustomBillIcon className={"w-[25px]"} color="#7E7E7E" />}
               activeIcon={
                  <CustomBillIcon className={"w-[25px]"} color={"#FFFFFF"} />
               }
               smallIcon={<CustomBillIcon className={"w-[25px]"} />}
               onClick={() => handleClick("Accounts")}
            />

            <NavItem
               label="History"
               to="/dashboard/transactions"
               isActive={activeLabel === "Transactions"}
               icon={
                  <CustomHistoryIcon className={"w-[25px]"} color="#7E7E7E" />
               }
               activeIcon={
                  <CustomHistoryIcon className={"w-[25px]"} color={"#FFFFFF"} />
               }
               smallIcon={<CustomHistoryIcon className={"w-[25px]"} />}
               onClick={() => handleClick("Transactions")}
            />

            <NavItem
               label="Settings"
               to="/dashboard/settings"
               isActive={activeLabel === "Settings"}
               icon={
                  <CustomSettingsIcon className={"w-[25px]"} color="#7E7E7E" />
               }
               activeIcon={
                  <CustomSettingsIcon
                     className={"w-[25px]"}
                     color={"#FFFFFF"}
                  />
               }
               smallIcon={<CustomSettingsIcon className={"w-[25px]"} />}
               onClick={() => handleClick("Settings")}
            />
         </div>

         {/* logout button bottom */}
         <div className="absolute bottom-0 left-0 right-0 hidden w-full font-semibold md:flex md:justify-center">
            <NavItem
               to="#"
               onClick={signOut}
               label="Logout"
               icon={
                  <CustomLogOutIcon
                     className={"mx-auto w-[40px] md:w-[20px]"}
                     color={"#7E7E7E"}
                  />
               }
            />
         </div>
      </aside>
   );
}
