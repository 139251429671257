import { useEffect, useRef } from "react";
import { IoCloseOutline } from "react-icons/io5";

import { classNames } from "utils/lib/getClassName";
import { useGlobalModalContext } from "./global-modal.context";
import * as MODAL_MAPS from "./maps.utils";

export default function ModalContainer({
   isOpen,
   children,
   onClose,
   hideOnBlur = true,
   showCloseBtn = true,
   variant = "FULL_SCREEN",
   size = "MEDIUM",
   color = "WHITE",
   modalPosition = "CENTER",
   ...rest
}) {
   const modalRef = useRef(null);
   const { hideModal } = useGlobalModalContext();

   /*
    *hideModal is called here to automatically hide global modals
    *onClose function can be called as a cleanup or btnOnClick function
    *but its main use here is to close non global modal i.e. set isOpe=false
    */
   const handleClose = () => {
      hideModal();
      onClose && onClose();
      document.body.style.overflow = "visible";
   };

   const handleOverlayClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
         hideOnBlur && handleClose();
      }
   };

   useEffect(() => {
      if (isOpen) {
         document.body.style.overflow = "hidden";
      } else {
         document.body.style.overflow = "visible";
      }
   }, [isOpen]);

   return (
      <div
         className={`${
            isOpen ? "block" : "hidden"
         } fixed top-0 left-0 z-50 h-full w-full bg-black-one bg-opacity-30`}
         {...rest}
         onClick={handleOverlayClick}
      >
         <div
            tabIndex={1}
            ref={modalRef}
            className={classNames(
               "fixed top-2/4 left-2/4 max-h-[90vh] -translate-y-2/4 -translate-x-2/4 overflow-hidden",
               MODAL_MAPS.VARIANT_MAPS[variant],
               MODAL_MAPS.SIZE_MAPS[size],
               MODAL_MAPS.COLOR_MAPS(variant)[color],
               MODAL_MAPS.POSITION_MAPS[modalPosition],
            )}
         >
            {showCloseBtn && (
               <IoCloseOutline
                  onClick={handleClose}
                  className="absolute right-4 top-3 z-[2] cursor-pointer rounded-3xl text-4xl hover:bg-secondary-lighter md:block"
               />
            )}
            {children}
         </div>
      </div>
   );
}
