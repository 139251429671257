import { axios } from "utils/lib/axios";

export const getBanks = async () => {
   return axios.get("payment/banks");
};

export const getNubanName = async (queryObj) => {
   return axios.post("payment/nuban-name-enquiry", queryObj);
};

export const getBanksUrl = "payment/banks";

export const getNubanUrl = "payment/nuban-name-enquiry";

export const updateBankDetails = async (queryObj) => {
   return axios.put("member/bank-details", queryObj);
};
